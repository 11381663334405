<template>
  <v-container class="p-4">
    <datatable-approve-requests
      @requestUpdated="getApprovedRequestsData"
      @approverUpdated="getApprovedRequestsData"
      @requestCanceled="getApprovedRequestsData"
      title="Aprobar Solicitudes de materiales"
      :approveRequests="materialsRequestsToApprove"
    ></datatable-approve-requests>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DatatableApproveRequests from "@/components/proyectos/lugarInstalacion/lugarSolicitudMaterial/approveRequests/DatatableApproveRequests.vue";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
export default {
  components: { DatatableApproveRequests },
  methods: {
    ...authMethods,
    ...mapActions({
      getAllUsersActiveContract: "user/getAllUsersActiveContract",
      getMaterialsRequestsToApprove:
        "requestMaterial/getMaterialsRequestsToApprove",
    }),
    async getApprovedRequestsData() {
      await this.getMaterialsRequestsToApprove({
        user: this.user.email,
      });
    },
  },
  mounted() {
    this.getApprovedRequestsData();
    this.getAllUsersActiveContract({
      date: new Date().toISOString().split("T")[0],
    });
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      materialsRequestsToApprove: "requestMaterial/materialsRequestsToApprove",
    }),
  },
};
</script>

<style></style>
